<template>
  <v-chip-group
    v-bind="$attrs"
    v-on="$listeners"
    :column="computedColumn"
    :show-arrows="computedShowArrows"
  >
    <v-chip
      :close="chipClose"
      :small="chipSmall"
      v-for="(item, index) in items"
      :key="index"
      @click:close="onChipRemoved(item)"
    >
      <slot v-bind:item="item">
        {{ getChipItem(item) }}
      </slot>
    </v-chip>
  </v-chip-group>
</template>

<script>
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "CoreChipGroup",
  inheritAttrs: false,
  mixins: [slottableMixin],
  props: {
    items: {
      type: Array,
      required: true
    },
    showArrows: {
      type: Boolean,
      default: undefined
    },
    column: {
      type: Boolean,
      default: undefined
    },
    chipSmall: {
      type: Boolean,
      default: false
    },
    chipClose: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedShowArrows() {
      return this.showArrows ? this.showArrows : false;
    },
    computedColumn() {
      return this.column ? this.column : false;
    }
  },

  methods: {
    /**
     * Event On Removed Chip
     * @param item
     */
    onChipRemoved(item) {
      this.$emit(eventNames.onSearchChipRemoved, item);
    },

    /**
     * Get Chip Item
     * @param item
     * @return {*}
     */
    getChipItem(item) {
      return this.chipClose ? item.value : item;
    }
  }
};
</script>
